<script setup lang="ts">
import { useInitLocale } from "@/composables/useLocaleWatcher";

useInitLocale();
const { crisp } = useCrisp();
crisp.hide();

// composables
const router = useRouter();
const route = useRoute("dashboard-organizations-orgId");
const { setLocale, t } = useI18n();

useHead({
  meta: [
    {
      hid: "viewport",
      name: "viewport",
      content: "width=device-width, initial-scale=0.55, minimum-scale=0.55",
    },
  ],
  titleTemplate: (title) => `${title || t("cloud-accounting")}`,
});

// methods
const navigateBack = () => {
  router.push({
    name: "dashboard-organizations-orgId",
    params: { orgId: route.params.orgId },
  });
};
</script>

<template>
  <MpLayout
    showBackBtn
    @navigateBack="navigateBack"
    @setLocale="setLocale"
  >
    <slot />
  </MpLayout>
</template>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  background-color: white;
  color: black;
}

#__nuxt {
  height: 100%;
}
</style>
